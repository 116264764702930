.home{


}

.featured-properties{
    padding: 4.8rem 0;
    
    .site-wrapper{
        margin-top: 0;
    }

    h3{
        display: block;
        width: 100%;
        // font-family: "Prospectus", serif;
        font-weight: 900;
        font-size: 2.8rem;
        padding: 0 20%;
        color: var(--color-primary-dark);
        margin: 2rem 0 6.8rem;
    }

    .site-wrapper{
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
