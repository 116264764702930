.site-header{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 14px 0;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    &__logo{
        max-width: 100px;
        width: 100%;
        height: auto;

    }

    nav{
        a{
            display: inline-block;
            margin-left: 24px;
            color: #fff;
            text-decoration: none;

            &:hover{
                color: rgba(0,0,0,.8);
            }
        }
    }
}

.menu-icon{
    width: 100%;
    max-width: 18px;
    height: 100%;
    max-height: 18px;
    position: relative;
    margin: 0;
    background: transparent;
    border: 0;
    cursor: pointer;

    &:before,
    &:after{
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: rgba(255,255,255,.9);
        position: absolute;
        left: 0;
    }

    &:before{
        top: 0;
    }

    &:after{
        bottom: 0;
    }

    &__bar{
        width: 80%;
        height: 2px;
        background: rgba(255,255,255,.9);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}
    

    

.site-menu{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;

    &__overlay{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #1B2224;
        opacity: 0;
        transition: opacity var(--transition);
        visibility: none;
        pointer-events: none;
        cursor: pointer;
    }

    &__panel{
        background: var(--color-primary-dark);
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 20;
        padding: 100px 40px 80px;
        max-width: 50%;
        width: 100%;
        min-width: 300px;
        height: 100%;
        text-align: left;
        transition: right var(--transition);

        .site-nav{
            a{
                color: white;
                font-size: 24px;
                font-weight: 400;
                display: block;
                text-decoration: none;
                margin-bottom: 30px;
            }
        }

        .site-menu__footer{
            position: absolute;
            bottom: 80px;

            a{
                display: inline-block;
                color: white;
                opacity: .5;
                text-decoration: none;
                margin: 16px 16px 0 0;
            }

            .social-media{
                a{
                    opacity: .8;
                }
            }
        }
    }

    &--active{
        pointer-events: auto;

        .site-menu__panel{
            right: 0;
        }

        .site-menu__overlay{
            pointer-events: auto;
            opacity: .6;
            visibility: visible;
        }
    }
}