:root{
  --color-primary-turquoise: #285C67;
  --color-primary-gold: #FFDC82;
  --color-primary-powder-blue: #DDE8EA;
  --color-primary-white: #fff;
  --color-primary-dark: #2A3335;
  --color-primary-black: #1D2526;

  --color-white-80: rgba(255,255,255,.8);
  --color-white-70: rgba(255,255,255,.7);
  --color-white-60: rgba(255,255,255,.6);
  --color-white-50: rgba(255,255,255,.5);
  --color-white-40: rgba(255,255,255,.4);
  --color-white-30: rgba(255,255,255,.3);
  --color-white-20: rgba(255,255,255,.2);
  --color-white-10: rgba(255,255,255,.1);

  --color-black-80: rgba(0,0,0,.8);
  --color-black-70: rgba(0,0,0,.7);
  --color-black-60: rgba(0,0,0,.6);
  --color-black-50: rgba(0,0,0,.5);
  --color-black-40: rgba(0,0,0,.4);
  --color-black-30: rgba(0,0,0,.3);
  --color-black-20: rgba(0,0,0,.2);
  --color-black-10: rgba(0,0,0,.1);

  --font-size-h1: 5.4rem;
  --font-size-h1-mobile: 4.0rem;
  --font-size-h2: 3.6rem;
  --font-size-h2-mobile: 2.8rem;
  --font-size-p: 1.6rem;

  --transition: .6s cubic-bezier(0.51, 0.36, 0.16, 0.81);
}

*{
  box-sizing: border-box;
}

html{
  font-size: 62.5%;
}

body{
  position: relative;
  font-size: 16px;
  color: var(--color-primary-black);
}

html,
body{
  width: 100%;
  height: 100%;
}

.site {
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: fieldwork-hum, sans-serif;
}

a{
  transition: all .5s ease-in-out;
  color: var(--color-primary-turquoise);
}

@font-face {
  font-family: "Prospectus";
  src: url("/fonts/Prospectus-L-Black.woff2") format("woff2");
  font-display: swap;
}

.banner{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  max-height: 67.5rem;
  height: 100vh;
  padding: 3.0rem 0;
  background-position: center center;
  background-size: cover;
  text-align: left;
  position: relative;

  &__content{
      position: relative;
      z-index: 2;
      color: var(--color-primary-white);
      margin-bottom: 3.0rem;
      max-width: 40.0rem;

      h2{
          font-size: var(--font-size-h1);
          margin-bottom: 0;
          max-width: 37.0rem;
          // font-family: "Prospectus", serif;
          font-weight: 900;
          font-style: normal;
          font-weight: regular;
      }

      p{
          font-size: 1.8rem;
          line-height: 2.4rem;
          color: var(--color-white-80);
      }
  }

  &__caption{
      display: block;
      margin-top: 8.0rem;
      color: var(--color-white-60);
      font-size: 1.4rem;
  }

  &__img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &::after{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: #364144;
      opacity: .7;
      width: 100%;
      height: 100%;
      z-index: 1;
  }

  &::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
      opacity: .7;
      width: 100%;
      height: 100%;
      z-index: 1;
  }
}

.btn{
  background: var(--color-primary-turquoise);
  color: var(--color-primary-white);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: .4rem;
  padding: 1.6rem 2rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  transition: all var(--transition);

  &:hover{
    background: #154954;
  }

  &:active{
    background: #0c343c;
  }
}

.grid{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1.6rem;

  [data-col='full']{
    grid-column: span 12 / auto;
  }

  [data-col='one-half']{
    grid-column: span 12 / auto;

    @media screen and (min-width: 76.8rem){
      grid-column: span 6 / auto;
    }
  }

  [data-col='one-third']{
    grid-column: span 4 / auto;
  }

  [data-col='two-thirds']{
    grid-column: span 8 / auto;
  }

  [data-col='one-quarter']{
    grid-column: span 3 / auto;
  }
  
  [data-col='three-quarters']{
    grid-column: span 9 / auto;
  }
}

input[type='text'],
input[type='email']{
  width: 100%;
  padding: 1.2rem;
  border-radius: .4rem;
  border: .1rem solid var(--color-black-30);
}

textarea{
  width: 100%;
  padding: .8rem;
  height: 120px;
  border-radius: .4rem;
  border: .1rem solid var(--color-black-30);
  max-width: 100%;
  min-width: 100%;
}

label{
  font-size: 1.4rem;
  font-weight: 600;
  opacity: .8;
}

.site-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 128rem;
  padding: 0 2.4rem;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 550rem){
    padding: 0 2.4rem;
  }

  @media screen and (min-width: 76.8rem){
    padding: 0 5.6rem;
  }
}


.newsletter{
  padding: 3rem 0 4rem;
  border-top: 1px solid var(--color-black-10);

  h4{
    width: 100%;
    text-align: center;
  }

  p{
    width: 100%;
    text-align: center;
  }

  .site-wrapper{
    flex-wrap: wrap;
  }

  .content-wrapper{
    width: 95%;
    max-width: 50rem;
    margin: 0 auto;
  }

  .grid{
    align-items: end;
  }
}

.page{
  padding-bottom: 8rem;

  .banner{
    background: var(--color-primary-dark);
    background-image: none !important;
  }
}