.properties{
    padding-bottom: 8rem;
    &__banner{
        max-height: 42.0rem !important;
        background: var(--color-primary-turquoise);
        background-image: none !important;
    }

    &__list{
        margin-top: 6rem;
        .site-wrapper{
            display: flex;
            flex-wrap: wrap;
        }

        .property-card{
            // width: calc(50% - 1rem);
        }
    }
}