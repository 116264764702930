.property{
    min-height: 80vh;
    
    .banner{
        max-height: 30.0rem;

        @media screen and (min-width: 76.8rem){
            max-height: 42.0rem;
        }

        @media screen and (min-width: 100rem){
            max-height: 64.0rem;
        }

        &__content{
            margin-bottom: 0;
        }

        h2{
            margin-bottom: 0rem;
            font-size: calc(var(--font-size-h2-mobile) + .5rem);
    
            @media screen and (min-width: 76.8rem){
                font-size: var(--font-size-h2);
            }
        }
    
        p{
            margin-top: 0;
            font-size: 1.4rem;
        }
    }
    .site-wrapper{
        flex-wrap: wrap;

       

    }
}

.gallery{
    &__thumb{
        img{
            width: 100%;
            height: auto;
            margin-bottom: 2rem;
        }
    }
}

.property-guts{
    position: relative;
}

.content-wrapper{
    flex-wrap: wrap;
    text-align: left;
    font-size: 1.8rem;
    line-height: 1.35;
    padding-top: 1rem;
    flex: 0 1 100%;

    @media screen and (min-width: 768px){
        justify-content: center;
        flex: 0 1 85%;
        padding-right: 5rem;
        padding-top: 3rem;
        margin: 0 auto;
    }

    h1{
        font-size: 2.8rem;
        line-height: 3.6rem;

        @media screen and (min-width: 76.8rem){
            font-size: 3.6rem;
            line-height: 4.2rem;
        }
    }
}

.contact-form{
    margin: 5rem 0;
    padding: 1rem 3rem 5rem;
    background: var(--color-primary-powder-blue);
    border-radius: 1rem;
    flex: 0 1 100%;
    position: sticky;
    top: 20px;

    @media screen and (min-width: 768px){
        padding: 2rem 5rem 5rem;
        flex: 0 1 50%;
    }

    h3{
        // font-family: "Prospectus", serif;
        font-size: var(--font-size-h2-mobile);
        margin-bottom: 1rem;
    }

    p{
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
        line-height: 2.2rem;
    }

    label{ 
        text-align: left;
        width: 100%;
        display: block;
        margin-bottom: .4rem;
        font-weight: 300;
    }

    input,
    textarea{
        border-color: darken( #DDE8EA, 18%) !important;
    }
}

.map{
    margin: 5rem 0 0 0;
}

