.property-card{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    min-height: 200px;
    background-size: cover;
    background-position: center center;
    border-radius: .4rem;
    width: calc(50% - .8rem);
    padding: 2rem;
    text-align: left;
    color: var(--color-primary-white);
    text-decoration: none;
    position: relative;
    margin: 8px 0;
    transform: translateY(0) scale(1);
    z-index: 2;
    transition: all var(--transition);

    &:hover{
        transform: translateY(0) scale(1.02);
        z-index: 3;

        &:before{
           opacity: 1;
        }
    }

    picture{
        filter: grayscale(.25);
    }

    &:nth-of-type(even){
        transform: translateY(16px) scale(1);

        &:hover{
            transform: translateY(16px) scale(1.02);
            z-index: 3;
        }
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #364144;
        opacity: .7;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
  
    &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        opacity: .7;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__content{
        position: relative;
        z-index: 2;

        *{
            margin: 0;
        }
    }

    @media screen and (min-width: 77.8rem){
        // width: calc(25% - 1rem);
        min-height: 280px;
    }

    &:after{
        content: "";
    }
}

.coming-to-market{
    border-radius: 0 0 4px 4px;

    &__banner{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding: .8rem 1rem;
        background: var(--color-primary-gold);
        color: rgba(0,0,0,.5);
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 4px 0 0;
        transform: translateY(-1.6rem);
        text-transform: uppercase;

        h5{
            margin: 0;
            padding: 0;
            line-height: 1;
        }

        img{
            display: inline-block;
            margin-right: .6rem;
        }
    }

    &:before,
    &:after{
        border-radius: 0 0 4px 4px;
    }
}