.site-footer{
    padding: 10rem 0 4rem;
    background: var(--color-primary-powder-blue);
    margin-top: 5rem;

    .site-wrapper{
        display: block;
    }

    img{
        margin-bottom: 5rem;
    }

    nav{
        a{
            text-decoration: none;
            font-size: 2.4rem;
            display: block;
            text-align: center;
            margin: 1.6rem 0;
            opacity: .8;

            &:hover{
                opacity: 1;
            }

            @media screen and (min-width: 76.8rem){
                display: inline-block;
                margin: 0 1.6rem;
            }
        }
    }

    &__meta{
        nav{
            margin-top: 10.0rem;

            a{
                font-size: 1.4rem;
                opacity: .8;
                display: inline-block;
                margin: 0 .8rem;
            }
        }
    }

    &__copyright{
        font-size: 1rem;
        margin-top: 8rem;
        color: var(--color-primary-turquoise);
        opacity: .4;
    }
}